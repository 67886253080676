import React, { useState } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { gql } from 'apollo-boost'
import { SetFirmwareVersion, SetFirmwareVersionVariables } from './__generated__/SetFirmwareVersion'
import { useMutation } from '@apollo/react-hooks'
import { FirmwareVersion as FirmwareVersionType } from '../../../__generated__/globalTypes'

interface IFirmwareVersion {
  value: string | null
  deviceId: string
}


const UPDATE_FIRMWARE_VERSION = gql`
  mutation SetFirmwareVersion($input: UpdateDeviceFirmwareVersionInput!) {
    updateDeviceFirmwareVersion(input: $input) {
      id
      firmwareVersion
    }
  }
`

export const FirmwareVersion = (props: IFirmwareVersion) => {
  const [selectedVersion, setSelectedVersion] = useState(props.value)
  const [save] = useMutation<SetFirmwareVersion, SetFirmwareVersionVariables>(UPDATE_FIRMWARE_VERSION)
  const firmwareVersions = Array.from(new Set(['PVT', 'YOCTO1', 'YOCTO2', 'YOCTO3', props.value || '']))

  return (
    <Select
      variant='outlined'
      value={selectedVersion}
      onChange={async e => {
        setSelectedVersion(e.target.value as string)

        await save({
          variables: {
            input: {
              id: props.deviceId,
              firmwareVersion: (e.target.value as FirmwareVersionType) || null
            }
          }
        })
      }}>
      <MenuItem>Unknown</MenuItem>
      {firmwareVersions.map(version => (
        <MenuItem key={version} value={version}>
          {version}
        </MenuItem>
      ))}
    </Select>
  )
}

import * as React from 'react'
import { gql } from 'apollo-boost'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Input,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { OSVersionDetail, OSVersionDetailVariables } from './__generated__/OSVersionDetail'
import { useParams } from 'react-router'
import { FormattedDateTime } from '../formatted-date-time'
import { Check, CloudDownloadOutlined } from '@material-ui/icons'
import { Spacer } from '@vestaboard/installables'

type OsVersionProps = IOsVersionProps

interface IOsVersionProps {}

const QUERY = gql`
  query OSVersionDetail($id: String!) {
    osVersion(id: $id) {
      id
      created
      commitSha
      hawkbitSoftwareModule
      downloadLink
      metadata
      deploy
      production
    }
  }
`

const useOsVersion = (id: string) => useQuery<OSVersionDetail, OSVersionDetailVariables>(QUERY, { variables: { id } })

export const OsVersion: React.FC<OsVersionProps> = props => {
  const { id } = useParams<{ id: string }>()
  const [pairingCode, setPairingCode] = React.useState('')
  const [ipAddress, setIpAddress] = React.useState('vestaboard.local')

  if (!id) throw Error()

  const { data, loading } = useOsVersion(id)

  if (!data || loading) return <CircularProgress />

  const metadata = data?.osVersion.metadata ? JSON.parse(data?.osVersion?.metadata) : {}

  const infoTable = (
    <Table>
      <TableRow>
        <TableCell>
          <strong>Vestaboard OS Version ID</strong>
        </TableCell>
        <TableCell>
          <code>{data.osVersion.id}</code>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Vestaboard OS Version Name</strong>
        </TableCell>
        <TableCell>{metadata?.versionName || 'N/A'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Commit SHA</strong>
        </TableCell>
        <a href={`https://github.com/Vestaboard/yocto-vestaboard-os/commit/${data?.osVersion?.commitSha}`}>
          <TableCell>{data?.osVersion?.commitSha.slice(0, 7)}</TableCell>
        </a>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Notes</strong>
        </TableCell>
        <TableCell>{metadata?.notes || 'N/A'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Production</strong>
        </TableCell>
        <TableCell>{data.osVersion.production ? <Check /> : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Deployed</strong>
        </TableCell>
        <TableCell>{data.osVersion.deploy ? <Check /> : ''}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Created</strong>
        </TableCell>
        <TableCell>
          <FormattedDateTime value={data.osVersion.created}></FormattedDateTime>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Commit (Link to GitLab/GitHub)</strong>
        </TableCell>
        <TableCell>
          <a href={`https://gitlab.com/vestaboard/yocto-build/-/commit/${data.osVersion.commitSha}`}>
            https://gitlab.com/vestaboard/yocto-build/-/commit/{data.osVersion.commitSha} (deprecated, use GitHub
            instead)
          </a>
          <Spacer />
          <a href={`https://github.com/Vestaboard/yocto-vestaboard-os/commit/${data.osVersion.commitSha}`}>
            https://github.com/Vestaboard/yocto-vestaboard-os/commit/{data.osVersion.commitSha}
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Hawkbit software module ID</strong>
        </TableCell>
        <TableCell>{data.osVersion.hawkbitSoftwareModule ?? '(none)'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <strong>Download link</strong>
        </TableCell>
        <TableCell>
          <Button
            variant='outlined'
            startIcon={<CloudDownloadOutlined />}
            onClick={() => (window.location.href = data.osVersion.downloadLink)}>
            Download
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>`swupdate` command {`(must ssh in)`}</TableCell>
        <TableCell>
          <TextField
            style={{ width: '100%' }}
            multiline
            value={`wget -O /tmp/update.swu "${data.osVersion.downloadLink}" && vb-install-swu /tmp/update.swu`}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>firmware update over network. {'(Devices on 3.1.x and later will allow this)'}</TableCell>
        <TableCell>
          <Input
            placeholder='Enter Pairing Code'
            value={pairingCode}
            onChange={e => setPairingCode(e.target.value)}
            required={true}
          />
          <Input
            placeholder='Enter IP Address'
            value={ipAddress}
            onChange={e => setIpAddress(e.target.value)}
            required={true}
          />
          <TextField
            style={{ width: '100%' }}
            multiline
            value={`curl -vX PUT -H "X-Vestaboard-Token: ${pairingCode}" -H "Content-Type: application/json" -d '{"id":"${data.osVersion.id}","sha":"${data.osVersion.commitSha}","url":"${data.osVersion.downloadLink}","SHA256":"${metadata?.SHA256}","MD5":"${metadata?.MD5}"}' http://${ipAddress}:7000/firmware`}
          />
        </TableCell>
      </TableRow>
    </Table>
  )

  return (
    <Box>
      <a href={'/os-versions'}>
        <Typography>{`< All OS Versions`}</Typography>
      </a>
      <Spacer />
      <Card>{infoTable}</Card>
    </Box>
  )
}
